











































































































































































































.editor {
  margin: 50px auto 0;
  max-width: 600px;
}

.block-item.condition {
  padding-top: 0px;

  &:before {
    content: " ";
    width: 8px;
    height: 8px;
    position: absolute;
    background: #9298ad;
    border-radius: 50%;
    left: calc(50% - 4px);
    top: -4px;
    z-index: 2;
  }
}

.condition-lines {
  height: 100px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
  }
}

svg {
  fill: #5f667e;

  path {
    fill: none;
    stroke: #9298ad;
  }
}

.plumbing {
  display: inline-block;
  width: 158px;
  height: 70px;
}

.if-branches {
  display: flex;
  align-items: center;
  justify-content: center;

  .if-yes-branch,
  .if-no-branch {
    width: 50%;
    justify-content: center;
    display: flex;
  }

  .if-yes {
    border: 2px solid #11866f;
    color: #11866f;
  }

  .if-no {
    border: 2px solid #e40e49;
    color: #e40e49;
  }

  .plumbing {
    position: absolute;
  }
}

.blocknode-path-label {
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
}
