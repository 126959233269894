






























































































































































































































































































































































































































































.segment {
  .form-group {
    margin-bottom: 0 !important;
  }
}
